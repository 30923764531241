import Breadcrumbs from '@components/common/Breadcrumbs';
import CustomLink from '@components/common/CustomLink';
import Grid from '@components/common/Grid';
// import Image from '@components/common/Image';
import OrganisedDetails from '@components/OrganisedDetails';
import React from 'react';
import RelatedArticles from '@components/common/RelatedArticles';
import SEO from '@components/common/SEO';
import { graphql } from 'gatsby';
import { labels } from '@src/constants';
import parse from 'html-react-parser';
import { renderBlocks } from '@src/helpers';

// set up breadcrumbs

const Artist = ({ pageContext, data }) => {
  const { language: lang, id } = pageContext;
  const {
    wpArtist: {
      seo,
      title,
      ACFArtista,
      ACFArtista: { info, category, nationality, link, content, relatedArticles },
      featuredImage: { node: featuredImage },
    },
    allWpArticle: { edges: articles },
  } = data;

  // add language to context provider
  // language based labels

  return (
    <>
      <SEO seo={seo} language={lang} />
      <Grid animKey={`page-${id}`} transition>
        <Grid className="col-span-12 bg-white py-12 lg:sticky lg:top-48 lg:z-9">
          <OrganisedDetails className="sm:col-span-6 lg:col-span-3">
            <p>
              <span className="block">{labels.artist[lang]}:</span>
              <span className="block">{title}</span>
            </p>
            {/* <Breadcrumbs current={title} parent={'artists'} /> */}
          </OrganisedDetails>
          <OrganisedDetails className="sm:col-span-6 lg:col-span-3">
            <p>{labels.dates[lang]}:</p>
            <>{parse(info)}</>
          </OrganisedDetails>
          <OrganisedDetails className="sm:col-span-6 lg:col-span-3">
            <p>
              <span className="block">{labels.info[lang]}:</span>
              <span className="block">{`${category}, ${nationality}`}</span>
            </p>
          </OrganisedDetails>
          <OrganisedDetails className="sm:col-span-6 lg:col-span-3">
            <p>
              <span className="block">{labels.connect[lang]}:</span>
              <CustomLink url={link.url} target="_blank">
                {link.title}
              </CustomLink>
            </p>
          </OrganisedDetails>
        </Grid>
        <h1 className="col-span-12 lg:col-span-6 text-md sm:text-lg tracking-headline">{title}</h1>
        <h2 className="col-span-12 lg:col-span-6 text-md sm:text-lg tracking-headline">{category}</h2>
        {renderBlocks(content, data.wpArtist)}
        {relatedArticles && articles && articles.length > 0 && <RelatedArticles articles={articles} />}
      </Grid>
    </>
  );
};

Artist.displayName = 'Artist';

export const data = graphql`
  query($id: String, $relatedQuery: String, $language: WpLanguageCodeEnum) {
    wpArtist(id: { eq: $id }) {
      title
      ACFArtista {
        nationality
        info
        relatedArticles
        content {
          ... on WpArtist_Acfartista_Content_Image {
            fieldGroupName
            borderBottom
            image {
              altText
              caption
              mediaDetails {
                width
                height
                sizes {
                  height
                  name
                  sourceUrl
                  width
                }
              }
              mediaItemUrl
            }
          }
          ... on WpArtist_Acfartista_Content_Copy {
            copy
            fieldGroupName
            title
            align
            columns
            paddingBottom
          }
          ... on WpArtist_Acfartista_Content_Video {
            borderBottom
            embedSource
            source
            fieldGroupName
            autoplay
            loop
            mute
            hideControls
            linkSource {
              sourceUrl
            }
          }
          ... on WpArtist_Acfartista_Content_MediaGrid {
            columns
            borderBottom
            fieldGroupName
            media {
              embedSource
              mediaType
              source
              linkSource {
                sourceUrl
              }
              image {
                altText
                caption
                mediaDetails {
                  width
                  height
                  sizes {
                    height
                    name
                    sourceUrl
                    width
                  }
                }
                mediaItemUrl
              }
            }
          }
          ... on WpArtist_Acfartista_Content_ExhibitionsGrid {
            fieldGroupName
            gridTitle
            exhibition {
              city
              location
              nation
              title
              year
            }
            borderBottom
          }
        }
        category
        link {
          target
          url
          title
        }
      }
      featuredImage {
        node {
          altText
          caption
          mediaDetails {
            width
            height
            sizes {
              height
              name
              sourceUrl
              width
            }
          }
          mediaItemUrl
        }
      }
      seo {
        ...seoFields
      }
    }
    allWpArticle(
      limit: 6
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: $relatedQuery } } } }
        language: { code: { eq: $language } }
      }
    ) {
      edges {
        node {
          id
          uri
          title
          date
          featuredImage {
            node {
              altText
              mediaDetails {
                width
                height
                sizes {
                  height
                  name
                  sourceUrl
                  width
                }
              }
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`;

export default Artist;
