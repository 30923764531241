import CustomLink from '@components/common/CustomLink';
import { GrFormNextLink } from 'react-icons/gr';
import React from 'react';
import { labels } from '@src/constants';
import useGeneralContext from '@components/hoc/useGeneralContext';

function Breadcrumbs({ parent, current }) {
  const { activeLang } = useGeneralContext();
  const isEn = activeLang === 'EN';

  function urlHandler(val) {
    switch (val) {
      case 'homepage':
        return isEn ? '/en/' : '/';
        break;
      case 'artists':
        return isEn ? '/en/artists/' : '/artisti/';
        break;
      case 'locations':
        return isEn ? '/en/locations/' : '/sedi/';
        break;
      case 'journal':
        return isEn ? '/en/journal/' : '/giornale/';
        break;
    }
  }

  return (
    <p>
      <span>
        <CustomLink url={urlHandler('homepage')}>{labels.homepage[activeLang]}</CustomLink>
      </span>
      <GrFormNextLink className="inline-block" />
      <span>
        <CustomLink url={urlHandler(parent)}>{labels[parent][activeLang]}</CustomLink>
      </span>
      <GrFormNextLink className="inline-block" />
      <span>{current}</span>
    </p>
  );
}

export default Breadcrumbs;
