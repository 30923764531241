import ContentList from '@components/blocks/ContentList';
import React from 'react';
import { labels } from '@src/constants';
import useGeneralContext from '@components/hoc/useGeneralContext';

export default function RelatedArticles({ articles }) {
  const { activeLang } = useGeneralContext();
  const title = labels.relatedArticles[activeLang];
  const cleanArticles = articles.map(item => item.node);

  return <ContentList title={title} articles={cleanArticles} borderTop smallGrid />;
}
