// take width
// take content
// styled components to style internal spans

import React from 'react';

function OrganisedDetails({ children, className }) {
  return <div className={`col-span-12 text-sm font-sans tracking-sans ${className}`}>{children}</div>;
}

export default OrganisedDetails;
